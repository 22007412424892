import { useLanguage } from "@/lib/hooks/useLanguage";
import { useTranslation } from "@/lib/i18n/client";
import { useState } from "react";
import Text from "../Text";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp } from "@fortawesome/pro-solid-svg-icons";
import PriceDetails, { PriceDetailsProps } from "./PriceDetails";
import { getPriceWithCurrency } from "@/lib/utils/reservation";

export type PriceSummaryProps = PriceDetailsProps & {
    priceTotal: number;
    isLoadingPrice: boolean;
};

const PriceSummary = ({ pricing, priceTotal, isLoadingPrice }: PriceSummaryProps) => {
    const lang = useLanguage();
    const { t } = useTranslation(lang, "checkout");
    const [showDetails, setShowDetails] = useState(false);

    if (isLoadingPrice) {
        return <div className="h-[80px] w-full bg-gray-300 rounded-md drop-shadow-md animate-pulse"></div>;
    }

    return (
        <div className="flex flex-col">
            <div className="flex flex-row text-lg font-bold justify-between">
                <Text>{t("payment-summary.title")}</Text>
                <Text>{getPriceWithCurrency(lang, priceTotal * 100)}</Text>
            </div>
            <div className="flex flex-row justify-between">
                <Text className="text-grayAlpha-600">{t("payment-summary.subtitle")}</Text>
                <Text
                    className="font-bold text-cyan-500 hover:cursor-pointer select-none"
                    onClick={() => setShowDetails(!showDetails)}
                >
                    {t("payment-summary.button-details")}
                    <FontAwesomeIcon icon={showDetails ? faChevronUp : faChevronDown} className="ml-1" />
                </Text>
            </div>
            {showDetails && <PriceDetails isLoadingPrice={isLoadingPrice} pricing={pricing} />}
        </div>
    );
};

export default PriceSummary;
