"use client";

import { useLanguage } from "@/lib/hooks/useLanguage";
import { useTranslation } from "@/lib/i18n/client";
import { getLanguageData } from "@/lib/i18n/settings";
import { addDays, format } from "date-fns";
import { GetRoomCategoryPriceResponse } from "@/lib/types/Search";
import { getPriceWithCurrency } from "@/lib/utils/reservation";
import { useContext } from "react";
import { PriceContext } from "@/context/PriceContext";

export type PriceDetailsProps = {
    pricing?: GetRoomCategoryPriceResponse[];
    isLoadingPrice: boolean;
};

const PriceDetails = ({ pricing, isLoadingPrice }: PriceDetailsProps) => {
    const lang = useLanguage();
    const { t } = useTranslation(lang, "checkout");
    const CRONJOB_NR_DAYS_BEFORE_MONTH = 7;
    const { diffPrices } = useContext(PriceContext);
    const newPricing = diffPrices || pricing;

    if (!pricing) {
        return null;
    }

    const renderSkeleton = () => (
        <>
            <div className="bg-gray-300 w-full h-[70px] rounded-md animate-pulse mt-2"></div>
            <div className="bg-gray-300 w-full h-[70px] rounded-md animate-pulse mt-2"></div>
        </>
    );

    if (isLoadingPrice) {
        return renderSkeleton();
    }

    return (
        <>
            {newPricing.map((price, i) => {
                const locale = getLanguageData(lang);

                const cronJobDate = addDays(new Date(price.period.startDate), -CRONJOB_NR_DAYS_BEFORE_MONTH);
                const dueOn = format(cronJobDate, "P", { locale: locale.dateLocale });
                const isFirst = i === 0;
                const borderColor = isFirst ? "cyan-500" : "gray-300";
                const { total, accommodationPrice, serviceFee, additionalGuestFee, cleaningFee, discount } = price;

                return (
                    <div
                        key={price.instalmentNumber}
                        className={`w-auto block border max-sm:justify-between max-sm:mb-4 sm:mb-4 rounded-md border-${borderColor} flex gap-2 ${
                            i === 0 ? " mt-2 " : ""
                        } ${i < pricing.length - 1 ? " mb-9 " : " "}`}
                    >
                        <div className={`flex flex-col w-full pt-1 pl-2 pr-3 pb-12.5 border-r border-dotted`}>
                            <div className="text-left max-sm:text-sm max:mdpr-16 pb-2 sm:flex gap-1 font-bold">
                                {i === 0
                                    ? t("payment-summary.details.due-now")
                                    : `${t("payment-summary.details.due-on")} ${dueOn}`}
                            </div>
                            <div className="max-sm:text-sm flex pb-1 justify-between text-gray-300">
                                <div>{t("payment-summary.details.monthly-price")}</div>
                                <div>{getPriceWithCurrency(lang, accommodationPrice)}</div>
                            </div>
                            {serviceFee > 0 && (
                                <div className="max-sm:text-sm flex pb-1 justify-between text-gray-300">
                                    <div>{t("payment-summary.details.service-fee")}</div>
                                    <div>{getPriceWithCurrency(lang, serviceFee)}</div>
                                </div>
                            )}
                            {cleaningFee > 0 && (
                                <div className="max-sm:text-sm flex pb-1 justify-between text-gray-300">
                                    <div>{t("payment-summary.details.cleaning-fee")}</div>
                                    <div>{getPriceWithCurrency(lang, cleaningFee)}</div>
                                </div>
                            )}
                            {additionalGuestFee > 0 && (
                                <div className="max-sm:text-sm flex pb-1 justify-between text-gray-300">
                                    <div>{t("payment-summary.details.additional-guests")}</div>
                                    <div>{getPriceWithCurrency(lang, additionalGuestFee)}</div>
                                </div>
                            )}
                            {discount > 0 && (
                                <div className="max-sm:text-sm flex justify-between text-green-500 font-bold">
                                    <div>{t("payment-summary.details.discount")}</div>
                                    <div>- {getPriceWithCurrency(lang, discount)}</div>
                                </div>
                            )}
                        </div>
                        <div className="max-sm:text-sm pt-1 px-2 flex flex-col justify-between font-bold text-center">
                            <div className="min-w-[75px] flex flex-col items-center gap-1">
                                <span>{getPriceWithCurrency(lang, total)}</span>
                            </div>
                        </div>
                    </div>
                );
            })}
        </>
    );
};

export default PriceDetails;
