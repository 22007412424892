"use client";
import { API_SEARCH } from "@/lib/services/searchService";
import { extractRoomSummaryFromUrl, replaceRoomSummaryInUrl } from "@/lib/utils/search";
import { usePathname, useRouter, useSearchParams } from "next/navigation";
import { SyntheticEvent, useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import ReservationData, { ReservationInfo } from "./ReservationData";
import { useLanguage } from "@/lib/hooks/useLanguage";
import { useTranslation } from "@/lib/i18n/client";
import PriceSummary from "./PriceSummary";
import Button from "../Button";
import Text from "../Text";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faOctagonExclamation, faPencil } from "@fortawesome/pro-regular-svg-icons";
import { faChevronLeft } from "@fortawesome/pro-solid-svg-icons/faChevronLeft";
import { faChevronUp } from "@fortawesome/pro-solid-svg-icons/faChevronUp";
import { faExclamationCircle } from "@fortawesome/pro-solid-svg-icons/faExclamationCircle";
import LinkButton from "../Link";
import { getUrl, Urls } from "@/lib/constants/urls";
import { buildCheckoutUrl, formatMoney } from "@/lib/utils/checkout";
import Modal from "../Modal";
import ModalHeader from "../Modal/ModalHeader";
import PriceDetails from "./PriceDetails";
import { BookingType, GetRoomCategoryPriceResponse, RoomUnavailableDays } from "@/lib/types/Search";
import { API_RESERVATION } from "@/lib/services/reservationsService";
import { differenceInDays, format, parseISO } from "date-fns";
import RoomSummaryHead, { RoomSummaryHeadProps } from "./RoomSummaryHead";
import { useTailwindBreakpoint } from "@/lib/hooks/useTailwindBreakpoint";
import { breakpointIsLessThan } from "@/lib/utils/tailwind";
import { SCREEN_SIZE } from "@/lib/constants/tailwind";
import Link from "next/link";
import { GoogleTagManagerEvents, RoomItem } from "@/lib/utils/googleTagEvents";
import { useAtomValue } from "jotai";
import { authAtom } from "@/lib/jotai/auth/authStore";
import { SEARCH_API_DATE_FORMAT } from "@/lib/constants/search";
import { de } from "date-fns/locale";
import SkeletonPrice from "../SkeletonPrice/SkeletonPrice";
import { PriceContext } from "@/context/PriceContext";

type RoomSummaryProps = {
    maxOccupancy: number;
    securityDeposit?: number;
    onChange: (reservation: ReservationInfo) => void;
    fetchMonthlyFee?: (price: number) => void;
    mode?: "room-details" | "checkout" | "readonly";
    showPriceDetails?: boolean;
    bookingType?: BookingType;
    children?: React.ReactNode;
    companyId?: string;
    isLoadingProp?: boolean;
    setIsLoading: (value: boolean) => void;
} & Pick<RoomSummaryHeadProps, "roomTitle" | "imageUrl">;

const RoomSummary = ({
    maxOccupancy,
    securityDeposit = 0,
    onChange,
    fetchMonthlyFee,
    mode = "room-details",
    bookingType = BookingType.Request,
    showPriceDetails = true,
    children,
    roomTitle,
    imageUrl,
    companyId,
    isLoadingProp = true,
    setIsLoading,
}: RoomSummaryProps) => {
    const searchUrlParams = useSearchParams();
    const router = useRouter();
    const pathName = usePathname();
    const reservationId = searchUrlParams.get("reservationId");
    const isFirstRender = useRef(true);
    const [roomSummary, setRoomSummary] = useState(reservationId ? null : extractRoomSummaryFromUrl(searchUrlParams));
    const guestCount = searchUrlParams.get("guestCount") ?? roomSummary?.guestCount;

    const lang = useLanguage();
    const { t } = useTranslation(lang, "checkout");

    const [pricing, setPricing] = useState<GetRoomCategoryPriceResponse[]>([]);
    const [unavailableDays, setUnavailableDays] = useState<RoomUnavailableDays>();
    const [displayFirstInstallment, setDisplayFirstInstallment] = useState<number>(0);
    const [isMobileModalOpen, setIsMobileModalOpen] = useState(false);
    const [isDepositModalOpen, setIsDepositModalOpen] = useState(false);
    const [isMobilePriceDetailsVisible, setIsMobilePriceDetailsVisible] = useState(false);
    const [isAvailable, setIsAvailable] = useState(true);
    const DESKTOP_BREAKPOINT: SCREEN_SIZE = "xl";
    const screenSize = useTailwindBreakpoint()[1];
    const isMobile = breakpointIsLessThan(screenSize, DESKTOP_BREAKPOINT);
    const [isLoadingPrice, setIsLoadingPrice] = useState(false);
    // Conditionally rendering by flags
    const isRoomDetailsMode = mode === "room-details";
    const isCheckoutMode = mode === "checkout";
    const isReadOnlyMode = mode === "readonly";
    const [isError, setIsError] = useState(false);

    const {
        pricingSaved,
        updatePricing,
        roomId,
        setRoomId,
        handleUnAvailableDays,
        unavailableDaysSaved,
        setDiffPrices,
    } = useContext(PriceContext);

    const auth = useAtomValue(authAtom);
    const userIDForGTM = auth.isLoggedIn ? auth.userInfo._id : undefined;

    // Format the startDate to "1.Okt."
    const formattedStartDate = roomSummary?.startDate
        ? format(new Date(roomSummary.startDate), "d. MMM.", { locale: de }).slice(0, -1)
        : "";
    const formattedEndDate = roomSummary?.endDate
        ? format(new Date(roomSummary.endDate), "d. MMM.", { locale: de }).slice(0, -1)
        : "";

    // load data from reservation in case of returning to page
    useEffect(() => {
        async function fn() {
            if (reservationId) {
                const reservation = await API_RESERVATION.getUserReservationDetails(`/${reservationId}`);
                setRoomSummary({
                    startDate: parseISO(reservation.checkinDate),
                    endDate: parseISO(reservation.checkoutDate),
                    guestCount: reservation.visitors.length,
                    roomId: reservation.roomCategory._id,
                });
            }
        }

        fn();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const prevRoomSummary = useRef(roomSummary);
    const prevGuestCount = useRef(guestCount);

    const memoizedRoomSummary = useMemo(
        () => roomSummary,
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [roomSummary?.startDate, roomSummary?.endDate, roomSummary?.roomId],
    );

    useEffect(() => {
        async function f1() {
            if (memoizedRoomSummary && memoizedRoomSummary.roomId === roomId) {
                setUnavailableDays(unavailableDaysSaved as RoomUnavailableDays);
                setIsAvailable(true);
                if (pricingSaved) {
                    setPricing([{ ...pricingSaved }]);
                    setDisplayFirstInstallment(pricingSaved?.total / 100);
                }
                setIsLoading(false);
            }
            if (isFirstRender.current) {
                isFirstRender.current = false;
                if (memoizedRoomSummary && memoizedRoomSummary.roomId !== roomId) {
                    try {
                        const res = await API_SEARCH.GetRoomCategoryCalendar({
                            startDate: memoizedRoomSummary.startDate,
                            endDate: memoizedRoomSummary.endDate,
                            roomCategoryId: memoizedRoomSummary.roomId,
                        });
                        setUnavailableDays(res);
                        handleUnAvailableDays(res);
                    } catch (e) {
                        console.error(e);
                    }
                    try {
                        setIsLoadingPrice(true);
                        setIsLoading(true);
                        const resPrice = await API_SEARCH.GetRoomCategoryPrice({
                            checkinDate: memoizedRoomSummary.startDate,
                            checkoutDate: memoizedRoomSummary.endDate,
                            roomCategoryId: memoizedRoomSummary.roomId,
                            numberOfAdults: guestCount as unknown as number,
                        });
                        setPricing(resPrice);

                        if (resPrice.length === 0) {
                            setIsAvailable(false);
                        } else {
                            setIsAvailable(true);
                            const pTotal = resPrice.reduce((prev, curr) => prev + curr.total, 0) / 100;
                            setDisplayFirstInstallment(resPrice[0].total / 100);
                            updatePricing(resPrice[0]);
                            setDiffPrices(resPrice);
                            if (fetchMonthlyFee) fetchMonthlyFee(pTotal);
                        }
                    } catch (error) {
                        setIsLoading(false);
                        setIsError(true);
                        console.log(error, "error");
                    } finally {
                        setIsLoadingPrice(false);
                        setIsLoading(false);
                    }
                }
            } else if (
                prevRoomSummary.current?.startDate !== memoizedRoomSummary?.startDate ||
                prevRoomSummary.current?.endDate !== memoizedRoomSummary?.endDate ||
                prevGuestCount.current !== guestCount
            ) {
                prevRoomSummary.current = memoizedRoomSummary;
                prevGuestCount.current = guestCount;

                if (memoizedRoomSummary) {
                    try {
                        const res = await API_SEARCH.GetRoomCategoryCalendar({
                            startDate: memoizedRoomSummary.startDate,
                            endDate: memoizedRoomSummary.endDate,
                            roomCategoryId: memoizedRoomSummary.roomId,
                        });
                        setUnavailableDays(res);
                        handleUnAvailableDays(res);
                    } catch (e) {
                        console.error(e);
                    }
                    try {
                        setIsLoadingPrice(true);
                        setIsLoading(true);
                        const resPrice = await API_SEARCH.GetRoomCategoryPrice({
                            checkinDate: memoizedRoomSummary.startDate,
                            checkoutDate: memoizedRoomSummary.endDate,
                            roomCategoryId: memoizedRoomSummary.roomId,
                            numberOfAdults: guestCount as unknown as number,
                        });
                        setPricing(resPrice);

                        if (resPrice.length === 0) {
                            setIsAvailable(false);
                        } else {
                            setIsAvailable(true);
                            const pTotal = resPrice.reduce((prev, curr) => prev + curr.total, 0) / 100;
                            setDisplayFirstInstallment(resPrice[0].total / 100);
                            updatePricing(resPrice[0]);
                            setDiffPrices(resPrice);
                            if (fetchMonthlyFee) fetchMonthlyFee(pTotal);
                        }
                    } catch (error) {
                        setIsLoading(false);
                        setIsError(true);
                        console.log(error, "error");
                    } finally {
                        setIsLoadingPrice(false);
                        setIsLoading(false);
                    }

                    /* const pricingAverage = resPrice[0].total / 100;

                     GoogleTagManagerEvents.viewRoomDetailsPage(
                         lang,
                         roomSummary ?.bookingType === undefined ? "booking_request" : "instant_request",
                         pricingAverage,
                         {
                             item_id: room?._id ?? "",
                             hotel_name: room.property.name ?? "",
                             hotel_type: room.roomType ?? "",
                             hotel_room_name: room.title,
                             hotel_city: room.property.address.city ?? "",
                             price: pricingAverage,
                         } as unknown as RoomItem,
                         guestCount,
                         bookingInDays.days as number,
                         checkIn,
                         checkOut,
                         "search_result_page",
                         auth.isLoggedIn ? auth.userInfo._id : undefined,
                     );*/
                }
            }
        }

        f1();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [roomSummary, guestCount]);

    const onReservationChange = useCallback(
        (data: ReservationInfo) => {
            data = { ...data, guestCount: guestCount } as ReservationInfo;
            const newUrlParams = replaceRoomSummaryInUrl(searchUrlParams, data);
            const url = `${pathName}?${newUrlParams}`;
            router.replace(url, {
                scroll: false,
            });
            if (roomSummary) {
                setRoomSummary({
                    ...roomSummary,
                    guestCount: guestCount as unknown as number,
                    /*
                                        startDate: data.checkIn.getHours() === 0 ? defaultCheckInTime : data.checkIn,
                                        endDate: data.checkOut.getHours() === 0 ? data.checkOut : defaultCheckOutTime,
                    */
                    startDate: data.checkIn,
                    endDate: data.checkOut,
                });

                if (onChange) {
                    onChange(data);
                }
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [guestCount],
    );

    const availabilityButton = (
        <div className="w-full p-4 border border-green-500 text-green-500 rounded-md text-center font-semibold">
            {t("dates-available")}
        </div>
    );

    const unAvailabilityButton = (
        <div className="w-full p-4 border border-red-500 text-red-500 rounded-md text-center font-semibold">
            {t("dates-unavailable")}
        </div>
    );

    const goToCheckout = (event: SyntheticEvent) => {
        updatePricing(pricing[0]);
        setDiffPrices(pricing);
        setRoomId(roomSummary?.roomId as string);
        const checkIn = format(new Date(roomSummary?.startDate ?? ""), SEARCH_API_DATE_FORMAT);
        const checkOut = format(new Date(roomSummary?.endDate ?? ""), SEARCH_API_DATE_FORMAT);

        const bookingInDays = differenceInDays(
            new Date(roomSummary?.endDate ?? ""),
            new Date(roomSummary?.startDate ?? ""),
        );

        GoogleTagManagerEvents.clickOnBookButton(
            lang,
            bookingType === BookingType.Instant ? "instant_request" : "booking_request",
            bookingInDays,
            displayFirstInstallment,
            guestCount as number,
            {
                item_id: roomSummary?.roomId,
                price: displayFirstInstallment,
            } as unknown as RoomItem,
            checkIn,
            checkOut,
            userIDForGTM,
        );

        event.stopPropagation();
        if (roomSummary) {
            // Carryover of the Ad Reveneu Google and other query string paramas
            const carryOverParams: { [key: string]: string } = {};
            for (const [key, value] of searchUrlParams.entries()) {
                carryOverParams[key] = value;
            }
            router.push(buildCheckoutUrl(lang, roomSummary, carryOverParams));
        } else {
            console.error("Room summary is null");
        }
    };

    const renderStatusAvailabilityButton = () => {
        if (isAvailable) {
            return availabilityButton;
        }
        return unAvailabilityButton;
    };

    const renderGoToCheckoutButtons = () => {
        if (isAvailable) {
            return (
                <>
                    <Button
                        size="md"
                        className="grow text-white font-bold px-7 block md:hidden"
                        onClick={(event: SyntheticEvent) => goToCheckout(event)}
                    >
                        {getBookingButtonText()}
                    </Button>
                    <Button
                        size="lg"
                        className="grow text-white font-bold px-7 hidden md:block"
                        onClick={(event: SyntheticEvent) => goToCheckout(event)}
                    >
                        {getBookingButtonText()}
                    </Button>
                </>
            );
        }

        return unAvailabilityButton;
    };

    const renderMonthlyAmountOnRoomDetailsMode = () => {
        if (isAvailable) {
            return <Text>{`${formatMoney(lang, displayFirstInstallment)}/${t("pricePerMonth")}`}</Text>;
        }
        return null;
    };

    const renderSecuirityDepositInfo = () => {
        return (
            <>
                {securityDeposit > 0 ? (
                    <Text
                        className="text-gray-500 text-center pt-4 hover:cursor-pointer"
                        onClick={() => setIsDepositModalOpen(true)}
                    >
                        <FontAwesomeIcon icon={faExclamationCircle} /> {t("security-deposit")}{" "}
                        {formatMoney(lang, securityDeposit / 100)}
                    </Text>
                ) : null}
            </>
        );
    };

    const getBookingButtonText = () =>
        bookingType === BookingType.Instant ? t("button-instant-booking") : t("button-request-booking");
    const checkIn = new Date(roomSummary?.startDate ?? "");
    const checkOut = new Date(roomSummary?.endDate ?? "");

    useEffect(() => {
        if (displayFirstInstallment > 0) {
            setIsLoading(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [displayFirstInstallment]);

    const bookingInDays = differenceInDays(checkOut, checkIn);

    return isLoadingProp || isLoadingPrice ? (
        <SkeletonPrice isCheckoutMode={isCheckoutMode} />
    ) : (
        <>
            <div
                className={`hidden ${
                    isCheckoutMode || isReadOnlyMode ? " w-full max-sm:flex max-sm:flex-col md:flex md:flex-col  " : ""
                }  lg:flex lg:flex-col`}
            >
                <RoomSummaryHead
                    price={displayFirstInstallment}
                    bookingInDays={bookingInDays}
                    roomTitle={roomTitle}
                    imageUrl={imageUrl}
                    isAvailable={isAvailable}
                    isError={isError}
                    showFrom={companyId === process.env.NEXT_PUBLIC_MYROOM24_COMPANY}
                />
                <div className={`flex flex-col p-5 gap-5 border-b`}>
                    {isRoomDetailsMode ? <div className="flex flex-col">{renderStatusAvailabilityButton()}</div> : null}
                    <div>
                        {roomSummary ? (
                            <ReservationData
                                guestCounReservation={roomSummary.guestCount + 6}
                                maxOccupancy={maxOccupancy}
                                checkIn={roomSummary.startDate}
                                checkOut={roomSummary.endDate}
                                unavailableDays={unavailableDays}
                                //mode={isReadOnlyMode ? "readonly" : "edit"}
                                mode={"edit"}
                                onReservationChange={onReservationChange}
                            />
                        ) : null}
                    </div>
                    {isCheckoutMode ? (
                        <div className={`text-center ${isMobile ? " mt-16 " : ""}`}>{renderSecuirityDepositInfo()}</div>
                    ) : null}
                </div>

                {showPriceDetails && isAvailable && (
                    <div className="flex flex-col p-5 gap-5 border-b">
                        <PriceSummary
                            priceTotal={displayFirstInstallment}
                            pricing={pricing}
                            isLoadingPrice={isLoadingPrice}
                        />
                        {isRoomDetailsMode && (
                            <div className="flex flex-col">
                                <Button
                                    className="grow text-white font-bold"
                                    onClick={(event: SyntheticEvent) => {
                                        goToCheckout(event);
                                    }}
                                >
                                    {getBookingButtonText()}
                                </Button>
                                <Text className="text-gray-500 text-center pt-4">{t("wont-be-charged-yet")}</Text>
                                {renderSecuirityDepositInfo()}
                            </div>
                        )}
                    </div>
                )}
                {children}
                {isRoomDetailsMode ? (
                    <div className="flex flex-col p-5 gap-5">
                        <LinkButton
                            onClick={() =>
                                GoogleTagManagerEvents.clickFAQorBecomeHostorContact(lang, "contact", userIDForGTM)
                            }
                            href={getUrl(Urls.contactUs.index, lang)}
                            variant="transparent"
                            className="font-bold"
                        >
                            {t("contact-us")}
                        </LinkButton>
                        <div className="flex flex-row justify-between font-bold">
                            <Link
                                onClick={() => GoogleTagManagerEvents.clickOnReportListing(lang, userIDForGTM)}
                                href={getUrl(Urls.contactUs.index, lang)}
                            >
                                <Text className="hover:text-primary-500">
                                    <FontAwesomeIcon icon={faOctagonExclamation} className="mr-1" />
                                    {t("report-listing")}
                                </Text>
                            </Link>
                            {/* <Text>
                                <FontAwesomeIcon icon={faArrowUpFromBracket} className="mr-1" />
                                {t("share")}
                            </Text> */}
                        </div>
                    </div>
                ) : null}
            </div>
            {isCheckoutMode || isReadOnlyMode ? null : (
                <>
                    <div
                        className="flex flex-row lg:hidden py-6 px-5 justify-between"
                        onClick={() => setIsMobileModalOpen(true)}
                    >
                        <div className="flex flex-col font-bold justify-between">
                            {!isMobile && (
                                <Text className="text-cyan-500 text-sm md:text-xl hcursor-pointer select-none">
                                    {t("payment-summary.button-details")}
                                    <FontAwesomeIcon icon={faChevronUp} className="ml-1" />
                                </Text>
                            )}
                            {isMobile && (
                                <Text className="text-gray-500 text-sm md:text-xl hcursor-pointer select-none underline">
                                    {formattedStartDate} - {formattedEndDate}
                                    <FontAwesomeIcon icon={faPencil} className="ml-1" />
                                </Text>
                            )}
                            {renderMonthlyAmountOnRoomDetailsMode()}
                        </div>
                        <div>{renderGoToCheckoutButtons()}</div>
                    </div>
                    <Modal
                        open={isMobileModalOpen}
                        setOpen={() => null}
                        mode="full-screen"
                        onClose={() => setIsMobileModalOpen(false)}
                        customScroll
                    >
                        <div className="overflow-y-auto flex flex-col h-full pb-24">
                            <ModalHeader
                                title={t("payment-summary.button-details") ?? ""}
                                onClose={() => {
                                    setIsMobileModalOpen(false);
                                    setIsMobilePriceDetailsVisible(false);
                                }}
                                className="p-6 pb-2"
                            />
                            {!isMobilePriceDetailsVisible && (
                                <div className="flex flex-col px-6 pt-2 !h-[calc(100%-80px)] overflow-y-auto">
                                    <div className="py-4">{renderStatusAvailabilityButton()}</div>
                                    <div>
                                        {roomSummary ? (
                                            <ReservationData
                                                unavailableDays={unavailableDays}
                                                maxOccupancy={maxOccupancy + 4}
                                                checkIn={roomSummary.startDate}
                                                checkOut={roomSummary.endDate}
                                                onReservationChange={onReservationChange}
                                                mode={"edit"}
                                            />
                                        ) : null}
                                    </div>

                                    <div className="mt-20 flex">
                                        <Button
                                            variant="transparent"
                                            className="grow text-center "
                                            onClick={() => setIsMobilePriceDetailsVisible(true)}
                                        >
                                            {t("payment-summary.mobile-button-view-payment-details")}
                                        </Button>
                                    </div>
                                    <div className="flex-col mt-4">{renderSecuirityDepositInfo()}</div>
                                </div>
                            )}
                            {isMobilePriceDetailsVisible && (
                                <div className="px-6 py-2">
                                    <div
                                        className="flex flex-row items-center text-gray-500 cursor-pointer"
                                        onClick={() => setIsMobilePriceDetailsVisible(false)}
                                    >
                                        <FontAwesomeIcon icon={faChevronLeft} className="mr-1" />
                                        <Text>
                                            {t("payment-summary.mobile-go-back-to-change-checkin-and-duration")}
                                        </Text>
                                    </div>
                                    <PriceDetails pricing={pricing} isLoadingPrice={isLoadingPrice} />
                                </div>
                            )}
                        </div>
                        {isAvailable && (
                            <div className="flex xl:hidden justify-between gap-2 px-6 py-4 bg-white border-gray-300 fixed bottom-0 left-0 right-0 shadow-lg-top z-10">
                                <Button
                                    size="lg"
                                    className="w-full text-white font-bold"
                                    onClick={(event: SyntheticEvent) => {
                                        goToCheckout(event);
                                    }}
                                >
                                    {getBookingButtonText()}
                                </Button>
                            </div>
                        )}
                    </Modal>
                </>
            )}
            <Modal
                open={isDepositModalOpen}
                setOpen={() => null}
                mode={"windowed"}
                onClose={() => setIsDepositModalOpen(false)}
                customScroll
            >
                <div className="text-left h-full p-6">
                    <ModalHeader
                        title={t("security-deposit") ?? ""}
                        onClose={() => {
                            setIsDepositModalOpen(false);
                        }}
                    />
                    <div>
                        <Text as={isMobile ? "h4" : "h3"} className="font-semibold">
                            {t("securityDepositModal.title")}{" "}
                        </Text>
                        <Text as="p" className="pt-2 sm:max-md:text-sm">
                            {t("securityDepositModal.paragraph-one")}{" "}
                        </Text>
                        <br />
                        <Text as="p" className="sm:max-md:text-sm">
                            {t("securityDepositModal.paragraph-two")}{" "}
                        </Text>
                        <br />
                        <Text as="p" className="sm:max-md:text-sm">
                            {t("securityDepositModal.paragraph-three")}{" "}
                        </Text>
                        <ul className="list-disc m-6 sm:max-md:text-sm">
                            <li>{t("securityDepositModal.methods.first")}</li>
                            <li>{t("securityDepositModal.methods.second")}</li>
                            <li>{t("securityDepositModal.methods.third")}</li>
                        </ul>
                    </div>
                </div>
                <div className="flex bg-white border-gray-300">
                    <Button
                        size="lg"
                        className="w-3/6 text-white font-bold mb-6 ml-6"
                        onClick={(ev: SyntheticEvent) => {
                            ev.preventDefault();
                            ev.stopPropagation();
                            setIsDepositModalOpen(false);
                        }}
                    >
                        {"Close"}
                    </Button>
                </div>
            </Modal>
        </>
    );
};

export default RoomSummary;
